/* eslint-disable no-console */
import { ApolloClient, InMemoryCache, HttpLink, concat } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import timeUtils from 'src/utils/time';
import authUtils from 'src/utils/auth';

import POST_REFRESH_ACCESS_TOKEN from 'src/graphql/queries/auth/refreshAccessToken';

let client;

// Query wrapper for direct apollo client calls
const refreshQuery = (currentRefreshToken) =>
  client.query({
    query: POST_REFRESH_ACCESS_TOKEN,
    variables: { refreshToken: currentRefreshToken },
  });

const authMiddleware = setContext(
  () =>
    new Promise((success, fail) => {
      const currentAccessToken = window.sessionStorage.getItem('accessToken');
      const accessTokenExpirationDate = window.sessionStorage.getItem('accessExpirationDate');
      if (currentAccessToken && !timeUtils.hasTimestampPassed(accessTokenExpirationDate)) {
        console.log('access token still valid');
        success({
          headers: {
            authorization: `Bearer ${currentAccessToken}`,
          },
        });
      } else {
        success({});
        const currentRefreshToken = window.localStorage.getItem('refreshToken');

        if (currentRefreshToken) {
          console.log('refreshing token apolloClient');
          refreshQuery(currentRefreshToken)
            .then(({ data }) => {
              const user = authUtils.processTokens(data.refreshAccessToken);
              success({
                headers: {
                  authorization: `Bearer ${user.accessToken}`,
                },
              });
            })
            .catch((err) => {
              console.error(err);
              fail(err);
            });
        }
      }
    }),
);
const httpLink = new HttpLink({ uri: process.env.NEXT_PUBLIC_GRAPHQL_URL });

client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export default client;
