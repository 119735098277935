import { gql } from '@apollo/client';

const GET_INTERESTS_CONFIG = gql`
  query GetInterestsConfigs($limit: Int) {
    getInterestsConfigs(limit: $limit) {
      instances {
        name
        _id
        icon
      }
    }
  }
`;

export default GET_INTERESTS_CONFIG;
