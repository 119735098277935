import { gql } from '@apollo/client';

const POST_REFRESH_ACCESS_TOKEN = gql`
  query RefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export default POST_REFRESH_ACCESS_TOKEN;
