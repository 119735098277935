/* eslint-disable no-console */
'use client';
import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';

import GET_APP_CONFIG from 'src/graphql/queries/base/getAppConfig';

const initialState = {
  isLoading: true,
  appData: null,
};

const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const appData = action.payload;

    return {
      ...state,
      ...(appData
        ? {
            isLoading: false,
            appData,
          }
        : {
            isLoading: false,
          }),
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// The role of this context is to propagate authentication state through the App tree.

export const AppContext = createContext({ undefined });

export const AppProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);
  const client = useApolloClient();

  const initialize = async () => {
    // Prevent from calling twice in development mode with React.StrictMode enabled
    if (initialized.current) {
      return;
    }

    initialized.current = true;
    let appData = null;

    try {
      const { data } = await client.query({
        query: GET_APP_CONFIG,
      });
      appData = data.getAppConfiguration;
    } catch (err) {
      console.error(err);
    }

    dispatch({
      type: HANDLERS.INITIALIZE,
      payload: appData,
    });
  };

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <AppContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

export const AppConsumer = AppContext.Consumer;

export const useAppContext = () => useContext(AppContext);
