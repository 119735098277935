import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import GET_INTERESTS_CONFIG from '@/graphql/queries/getInterestsConfig';

const initialState = {
  isLoading: true,
  interestsData: null,
};

const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const interestsData = action.payload;

    return {
      ...state,
      ...(interestsData
        ? {
            isLoading: false,
            interestsData,
          }
        : {
            isLoading: false,
          }),
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const InterestsContext = createContext({ undefined });

export const InterestsProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);
  const client = useApolloClient();

  const initialize = async () => {
    // Prevent from calling twice in development mode with React.StrictMode enabled
    if (initialized.current) {
      return;
    }

    initialized.current = true;
    let interestsData = null;

    try {
      const { data } = await client.query({
        query: GET_INTERESTS_CONFIG,
        variables: {
          limit: 99, // Aquí se añade el input `limit=99`
        },
      });
      interestsData = data.getInterestsConfig;
    } catch (err) {
      console.error(err);
    }

    dispatch({
      type: HANDLERS.INITIALIZE,
      payload: interestsData,
    });
  };

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <InterestsContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </InterestsContext.Provider>
  );
};

InterestsProvider.propTypes = {
  children: PropTypes.node,
};

export const useInterestsContext = () => useContext(InterestsContext);
