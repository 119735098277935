/* eslint-disable no-console */
'use client';
import { createContext, useContext, useEffect, useReducer, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { useRouter, usePathname } from 'next/navigation';
import Swal from 'sweetalert2';

import timeUtils from 'src/utils/time';
import authUtils from 'src/utils/auth';
import POST_REFRESH_ACCESS_TOKEN from 'src/graphql/queries/auth/refreshAccessToken';
import POST_SIGNIN from 'src/graphql/queries/auth/signIn';
import MARKETPLACE_SIGNUP from 'src/graphql/queries/auth/signupMarketplace';
import NETWORK_LINK_ACCOUNT from '@/graphql/queries/auth/networkLinkProfile';
import translateGraphqlError from '@/utils/translateGraphqlError';

const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_UP: 'SIGN_UP',
  LINK_ACCOUNT: 'LINK_ACCOUNT',
};

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  user: null,
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      ...// if payload (user) is provided, then is authenticated
      (user
        ? {
            isAuthenticated: true,
            isLoading: false,
            user,
          }
        : {
            isLoading: false,
          }),
    };
  },
  [HANDLERS.SIGN_IN]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  [HANDLERS.SIGN_OUT]: (state) => {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  },
  [HANDLERS.SIGN_UP]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  [HANDLERS.LINK_ACCOUNT]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// The role of this context is to propagate authentication state through the App tree.

export const AuthContext = createContext({ undefined });

export const useAccessToken = () => {
  const { isAuthenticated, user } = useAuthContext();

  if (isAuthenticated && user && user.accessToken) {
    return user.accessToken;
  }

  return null;
};

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);
  const client = useApolloClient();
  const router = useRouter();
  const pathname = usePathname();

  const initialize = async () => {
    console.log('initialize');
    // Prevent from calling twice in development mode with React.StrictMode enabled
    if (initialized.current) {
      return;
    }

    initialized.current = true;

    let isAuthenticated = false;
    let currentRefreshToken = null;
    let currentExpirationDate = null;
    let user = null;

    try {
      currentRefreshToken = window.localStorage.getItem('refreshToken');
      currentExpirationDate = window.localStorage.getItem('expirationDate');
      if (!timeUtils.hasTimestampPassed(currentExpirationDate)) {
        console.log('refreshing token');
        const { data } = await client.mutate({
          mutation: POST_REFRESH_ACCESS_TOKEN,
          variables: {
            accessToken: null,
            refreshToken: currentRefreshToken,
          },
        });
        console.log('data', data);
        if (data.refreshAccessToken) {
          user = authUtils.processTokens(data.refreshAccessToken);
          isAuthenticated = true;
        }
      }
    } catch (err) {
      console.error(err);
    }
    console.log('isAuthenticated', isAuthenticated);

    if (currentRefreshToken && currentExpirationDate) {
      dispatch({
        type: HANDLERS.INITIALIZE,
        payload: user,
      });
      console.log('pathname', pathname);
      if (pathname !== '/' || pathname !== '/events') {
        router.push(pathname);
      } else {
        router.push('/events');
      }
    } else {
      dispatch({
        type: HANDLERS.INITIALIZE,
      });
    }
  };

  const initializeCallback = useCallback(initialize, [initialize, pathname]);

  useEffect(() => {
    // Call initialize only when pathname is available
    if (pathname !== null) {
      initializeCallback();
    }
  }, [initializeCallback, pathname]);

  const signIn = async (email, password) => {
    console.log('signIn');

    if (password.length < 1 && email.length < 1) {
      throw new Error('Credenciales no ingresadas');
    }

    const { data } = await client.mutate({
      mutation: POST_SIGNIN,
      variables: {
        email,
        password,
        app: 'marketplace',
      },
    });
    let user = null;
    try {
      user = authUtils.processTokens(data.signin);

      if (user.accountLinkRequired) {
        router.push('/auth/linkAccount');
      } else {
        router.push('/events');
      }
    } catch (err) {
      console.error(err);
    }

    dispatch({
      type: HANDLERS.SIGN_IN,
      payload: user,
    });
  };

  const signUp = async (payload) => {
    console.log('signUp');

    let user = null;
    try {
      const { data } = await client.mutate({
        mutation: MARKETPLACE_SIGNUP,
        variables: { input: payload },
      });

      user = authUtils.processTokens(data.signupMarketplace.tokens);
      router.push('/events');
    } catch (error) {
      let errorMsg = error.message;
      if (error.graphQLErrors) {
        errorMsg = translateGraphqlError(error);
      } else if (error.networkError) {
        errorMsg = error.networkError.message;
      }

      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: errorMsg,
      }).then(() => router.replace('/'));
    }

    dispatch({
      type: HANDLERS.SIGN_UP,
      payload: user,
    });
  };

  const linkAccount = async (payload) => {
    console.log('linkAccount');

    let user = null;
    try {
      await client.mutate({
        mutation: NETWORK_LINK_ACCOUNT,
        variables: { input: payload },
      });

      router.refresh();
    } catch (error) {
      let errorMsg = error.message;
      if (error.graphQLErrors) {
        errorMsg = translateGraphqlError(error);
      } else if (error.networkError) {
        errorMsg = error.networkError.message;
      }

      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: errorMsg,
      }).then(() => router.replace('/'));

      dispatch({
        type: HANDLERS.LINK_ACCOUNT,
        payload: user,
      });
    }
  };

  const signOut = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    dispatch({
      type: HANDLERS.SIGN_OUT,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        linkAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuthContext = () => useContext(AuthContext);
