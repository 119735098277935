import { decodeToken } from 'react-jwt';
import moment from 'moment';

const getExpirationDate = (hours) => {
  const futureTimestamp = moment().add(hours, 'hours').valueOf();
  return futureTimestamp;
};

const processTokens = (tokens) => {
  const { accessToken, refreshToken } = tokens;
  const decodedToken = decodeToken(accessToken);

  const user = {
    userId: decodedToken?._id,
    role: decodedToken?.role,
    permissions: decodedToken?.permissions,
    organization: decodedToken?.organization || null,
    verifiedList: decodedToken?.verifiedList,
    info: decodedToken?.user,
    accountLinkRequired: !!decodedToken?.accountLinkRequired,
    serviceTier: decodedToken?.serviceTier,
    accessToken,
  };

  window.sessionStorage.setItem('accessToken', accessToken);
  window.sessionStorage.setItem('accessExpirationDate', getExpirationDate(1));
  window.localStorage.setItem('refreshToken', refreshToken);
  window.localStorage.setItem('expirationDate', getExpirationDate(5));
  return user;
};

export default processTokens;
