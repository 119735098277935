const translate = (errorMessage) => {
  if (errorMessage.includes('E11000 duplicate key error')) {
    return 'Correo electrónico inválido o ya está en uso';
  }
  return errorMessage;
};

const translateGraphqlError = (graphQLError) => {
  const errorInstances = graphQLError.graphQLErrors;
  const responses = errorInstances.map((errorInstance) => {
    const errorMessage = errorInstance.message;
    const errorExtensionMessage = errorInstance.extensions.message;
    return `${errorMessage}: ${translate(errorExtensionMessage)}`;
  });
  return responses.join('\n');
};

export default translateGraphqlError;
