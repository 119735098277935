import { gql } from '@apollo/client';

const MARKETPLACE_SIGNUP = gql`
  mutation SignupMarketplace($input: SignupMarketplaceInput!) {
    signupMarketplace(input: $input) {
      tokens {
        accessToken
        refreshToken
      }
      user {
        _id
      }
    }
  }
`;

export default MARKETPLACE_SIGNUP;
